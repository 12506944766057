import type { StyleFunctionProps, Theme } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'
import * as components from 'lib/chakra/components'
import { COLORS } from './constants'

export const theme = extendTheme(
  {
    fonts: {
      heading: 'var(--font-hoves)',
      body: 'var(--font-hoves)',
    },
    components: { ...components },
    styles: {
      global: (props: StyleFunctionProps) => ({
        'html, body': {
          minHeight: '100vh',
          background: mode('white', 'grey10')(props),
        },
        '.zircuit-toast-container': {
          bgColor: mode('white', 'dark1')(props),
          border: '1px solid',
          borderTop: '2px solid',
          borderColor: mode('grey07', 'darkGrey07')(props),
          '& > button': {
            color: mode('dark01', 'white')(props),
          },
        },
      }),
    },
    colors: COLORS,
  },
  {
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
  },
) as Theme
